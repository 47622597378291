import React from 'react'
import { SEO } from '../components/UI'
import {
  InternalHeader,
  InternalContent,
  InternalWorkshopNoTab,
  InternalInstructor,
  InternalTestimonial
} from '../components/Internal'
import { WHATSAPPLINK } from '../constants/routes'
import IconFlower from '../images/common/iconFlower.svg'
import styles from './florais-de-bach-para-animais.module.css'
export default function Page() {
  return (
    <>
      <SEO title='Florais de Bach para Animais' />
      <InternalHeader withType>
        <small>Online</small>
        <h1>
          Florais de Bach
          <br />
          para <strong>Animais</strong>
        </h1>
      </InternalHeader>
      <section className={styles.container}>
        <div className={styles.content}>
          <InternalContent>
            <h2>
              Sobre o <strong>Curso</strong>
            </h2>
            <p>
              Estudos recentes revelam que assim como os humanos, os animais podem passar por sofrimentos e problemas
              como depressão, tristeza, saudade, solidão e até perda do ânimo de viver. Quase todos que convivem com
              animais ou trabalham com eles são capazes de afirmar que eles têm um temperamento definido e
              individualizado, ou seja, possuem personalidade distinta e comportam-se de forma diferente mediante a
              mesma situação.
            </p>
            <h2>Objetivo</h2>
            <p>
              Preparar terapeutas, veterinários, pessoas engajadas ou apaixonados pela vida animal, a indicar os Florais
              de Bach com conhecimento sobre a atuação das essências no comportamento animal e no resgate dos padrões
              energéticos da saúde emocional.
            </p>
            <h2>
              O que você <strong>vai aprender</strong>
            </h2>
            <dl className='learnItems'>
              <dt>
                <IconFlower role='presentation' className='iconFlower' />
                Os Animais e suas emoções primárias
              </dt>
              <dd>breve relato e considerações iniciais</dd>

              <dt>
                <IconFlower role='presentation' className='iconFlower' />A prática terapêutica com Florais de Bach
              </dt>
              <dd>
                processo de escolha das essências florais para Animais e a Ressonância Empática (a dinâmica da relação
                interespécies)
              </dd>

              <dt>
                <IconFlower role='presentation' className='iconFlower' />
                Como são produzidos os Florais de Bach
              </dt>
              <dd>método solar e método de ebulição e as apresentações de uso</dd>

              <dt>
                <IconFlower role='presentation' className='iconFlower' />
                Formas e Aplicações de Uso dos Florais para animais
              </dt>
              <dd>como administrar e opções práticas de uso</dd>

              <dt>
                <IconFlower role='presentation' className='iconFlower' />
                Floral para emergências
              </dt>

              <dt>
                <IconFlower role='presentation' className='iconFlower' />
                Descrição das 38 Essências
              </dt>
              <dd>aplicabilidade para tutores e animais</dd>

              <dt>
                <IconFlower role='presentation' className='iconFlower' />
                Vida animal e a interação com seus tutores
              </dt>
              <dd>medo, ansiedade, estresse, resgate e traumas</dd>

              <dt>
                <IconFlower role='presentation' className='iconFlower' />
                Os Florais de Bach e problemas específicos
              </dt>
              <dd>hiperatividade, dominação, agressividade, ansiedade por separação, coprofagia, entre outros</dd>
            </dl>
          </InternalContent>
          <div className='aside'>
            <InternalWorkshopNoTab>
              <h3 className={styles.titleSide}>
                <small>Curso à Distância</small>
                Florais de Bach <br />
                para Animais
              </h3>
              <ul>
                <li>Aulas online agendadas ou gravadas</li>
                <li>Tira dúvidas via waths ou e-mail</li>
                <li>Material complementar incluso</li>
                <li>Certificado de conclusão</li>
                <li>Sem pré-requisitos</li>
                <li>Curso destinado a todos os públicos </li>
                <li>Acesso por 12 meses a plataforma de ensino</li>
              </ul>
              <a
                href={WHATSAPPLINK}
                target='_blank'
                rel='noopener noreferrer'
                className={`button button--purpleReverse button internalWorkshop-button`}
              >
                Inscrição Curso Online <small></small>
              </a>
            </InternalWorkshopNoTab>
            <InternalInstructor />
            <InternalTestimonial />
          </div>
        </div>
      </section>
    </>
  )
}
